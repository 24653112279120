import { Languages } from '@workerbase/domain/common';
import { ProjectGET, ProjectLogGET } from '@workerbase/types/api/projects';
import { TriggerGET } from '@workerbase/types/api/rules';
import { TriggerTypes } from '@workerbase/domain/rule';
import { Project } from 'services/types/Project';
import { ProjectLog } from 'services/types/ProjectLog';
import { Trigger } from 'services/types/Rule/Trigger';
import { omit } from 'lodash';

export const normalizeProject = (project: Partial<ProjectGET> & { _id: string }): Project => {
  const {
    _id,
    adminRoles,
    roles,
    config,
    name,
    description,
    meta,
    taskCount,
    language,
    archiveTasks,
    lastAutoArchivedAt,
  } = project;
  return {
    id: _id,
    adminRoles: adminRoles?.map((roleOrId) => (typeof roleOrId === 'string' ? roleOrId : roleOrId?._id)) || [],
    archiveTasks: archiveTasks || [],
    lastAutoArchivedAt: lastAutoArchivedAt ?? null,
    roles: roles?.map((roleOrId) => (typeof roleOrId === 'string' ? roleOrId : roleOrId?._id)) || [],
    rolesRaw: roles,
    adminRolesRaw: adminRoles,
    config: config || { taskFilter: [], taskDetailsFilter: [] },
    name: name || '',
    description: description || '',
    createdAt: meta?.createdAt || new Date(),
    taskCount: taskCount || {},
    language: language || Languages.EN,
  };
};

export const normalizeTrigger = (trigger: TriggerGET): Trigger => {
  const normalizedTrigger: Trigger = {
    ...trigger,
    disabled: !!trigger.disabled,
  };

  if (normalizedTrigger.type === TriggerTypes.INTERNAL_EVENT) {
    return omit(normalizedTrigger, 'resourceId'); // as TriggerInternalEvent
  }

  return normalizedTrigger; // as TriggerExternalEvent
};

export const normalizeProjectLog = ({ _id, level, action, meta, payload }: ProjectLogGET): ProjectLog => ({
  id: _id,
  level,
  action,
  meta: { createdAt: meta.createdAt || new Date() }, // Question: Can meta.createdAt ever be null or undefined?
  payload: payload || {}, // Question: Can payload ever be null or undefined? If yes then we need to update payload type
});
